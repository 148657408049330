import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import styled from "styled-components"
import Button from "@material-ui/core/Button"
import { Link } from "gatsby"

const StyledFormWrpaper = styled.div`
  padding: 40px 0;
`
const StyledTextField = styled(TextField)`
    display: block;
    width: 100%;
    margin: 30px 0 !important;
`
const StyledButton = styled(Button)`
  display: block;
  width: 100%;
`
const MessageSendLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/message-send" {...props} />
))

const PaymentContact = () => {
  const [values, setValues] = React.useState({
    name: "",
    email: "",
  })
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value })
  }

  return (
    <Layout>
      <SEO title="Home"/>
      <Container maxWidth={"lg"}>
        <StyledFormWrpaper>
          <Typography>Wypełniając pole poniżej możesz się z nami skontaktować. Jeśli masz jakieś uwagi lub pomysł jak możemy rozwijać naszą aplikację to prosimy Cię o kontakt.</Typography>
          <StyledTextField
            id="outlined-name"
            label="Wiadomość"
            multiline
            rows="4"
            fullWidth
            value={values.name}
            onChange={handleChange("name")}
            margin="normal"
            variant="outlined"
          />
        </StyledFormWrpaper>
        <StyledFormWrpaper>
          <Typography>Chcesz otrzymywać informacje na bierząco? Zostaw nam swojego maila!</Typography>
          <StyledTextField
            id="outlined-name"
            label="Email"
            value={values.email}
            fullWidth
            onChange={handleChange("email")}
            margin="normal"
            variant="outlined"
          />
          <StyledButton variant="contained" color="primary" size="large" component={MessageSendLink}>Wyślij</StyledButton>
        </StyledFormWrpaper>
      </Container>
    </Layout>
  )
}

export default PaymentContact
