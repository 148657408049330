import PropTypes from "prop-types"
import React from "react"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import MenuIcon from "@material-ui/icons/Menu"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import styled from "styled-components"
import InboxIcon from "@material-ui/icons/MoveToInbox"
import MailIcon from "@material-ui/icons/Mail"
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "block",
    textDecoration: "none",
    color: "#fff",
  },
}))

const StyledDrawerWrapper = styled.div`
  width: 70vw;
`
const StyledListItemLink = styled(Link)`
  display: flex;
  color: inherit;
  text-decoration: none;
  align-items: center;
`

const Header = ({ siteTitle }) => {
  const classes = useStyles()
  const [state, setState] = React.useState({
    open: false,
  })

  const toggleDrawer = (open) => event => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return
    }
    setState({ ...state, open })
  }

  const fullList = side => (
    <StyledDrawerWrapper role="presentation" onClick={toggleDrawer(side, false)} onKeyDown={toggleDrawer(side, false)}>
      <List>
        <ListItem>
          <StyledListItemLink to={"/scan-qr-code"}>
            <ListItemIcon><InboxIcon/></ListItemIcon>
            <ListItemText primary={"Zapłać"}/>
          </StyledListItemLink>
        </ListItem>
        <ListItem button>
          <StyledListItemLink to={"/restaurants"}>
            <ListItemIcon><MailIcon/></ListItemIcon>
            <ListItemText primary={"Restauracje"}/>
          </StyledListItemLink>
        </ListItem>
      </List>
      <Divider/>
      <List>
        {["Kariera", "O nas", "Kontakt"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon/> : <MailIcon/>}</ListItemIcon>
            <ListItemText primary={text}/>
          </ListItem>
        ))}
      </List>
    </StyledDrawerWrapper>
  )

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Open drawer"
            onClick={toggleDrawer(true)}>
            <MenuIcon/>
          </IconButton>
          <Link to={"/"}>
            <Typography className={classes.title} variant="h6" noWrap>
              {siteTitle}
            </Typography>
          </Link>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer anchor="left" open={state.open} onClose={toggleDrawer(false)}
                       onOpen={toggleDrawer(true)}>
        {fullList("top")}
      </SwipeableDrawer>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
